import "./DesktopOfferList.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reserveSection } from "../../Api/reservation";
import SearchInput from "../SearchInput/SearchInput";
import Tabs from "../tabs/Tabs";
import { useToastError, useToastInfo } from "../../hooks/useToast";
import { IOfferedSubject, IQuota, IReserveError, ISelectedSection } from "../../types";
import { normalizeText } from "../../utils";
import { selectStudent, setStudentInicializado } from "../../reduxSlices/studentSlice";
import {
  removeSelectedOfferedSubject,
  selectFilterTagsList,
  selectInscribedSubjects,
  selectOfferedSubjects,
  selectPinnedSubjectsList,
  selectSearchSubjectsValue,
  selectSelectedFilterTagsList,
  selectSelectedOfferedSubjects,
  selectSubjectsQuotas,
  setLoadingRequestInTransit,
} from "../../reduxSlices/subjectsSlice";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import DesktopTab from "../DesktopTab/DesktopTab";
import DesktopSubjectList from "../DesktopSubjectList/DesktopSubjectList";
import {
  selectPanelFilters,
  setInTransitInscriptionRequest,
  setPanelFilters,
} from "../../reduxSlices/globalFlagsSlice";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { tab } from "@testing-library/user-event/dist/tab";

const filterSubjects = (subjects: IOfferedSubject[], searchTerm: string): IOfferedSubject[] => {
  const filteredSubjects = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        const matchesTitle = normalizeText(section.section).includes(normalizeText(searchTerm));
        const matchesProf = section.subjectData.subjectsByType.some((subject) => {
          return subject.subjectInfo.some((info) => {
            return info.values.profesores.some((prof) => {
              return normalizeText(prof).includes(normalizeText(searchTerm));
            });
          });
        });
        return matchesTitle || matchesProf;
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);
  return filteredSubjects;
};

const filterSubjectsByTag = (subjects: IOfferedSubject[], filterTagsList: string[]) => {
  if (!filterTagsList.length) {
    return subjects;
  }
  const subjectsByFilter = subjects
    .map((s: IOfferedSubject) => {
      const filteredSections = s.sections.filter((section) => {
        // Verificar que 'tag' esté definido y sea una cadena
        if (typeof section.tag !== "string") {
          return false;
        }
        // Dividir la cadena 'tag' y limpiar los espacios
        const tags = section.tag.split(",").map((item) => item.trim());
        // Verificar si algún elemento de 'tags' está en 'filterTagsList'
        // console.log(tags.some((item) => filterTagsList.includes(item)));
        return tags.some((item) => filterTagsList.includes(item));
      });

      return {
        ...s,
        sections: filteredSections,
      };
    })
    .filter((s: IOfferedSubject) => s.sections.length > 0);

  return subjectsByFilter;
};

const DesktopOfferList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector(selectStudent);
  const selectedSubjects = useSelector(selectSelectedOfferedSubjects);
  const offeredSubjects = useSelector(selectOfferedSubjects);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);
  const panelFilters = useSelector(selectPanelFilters);
  const selectedFilterTagsListStore = useSelector(selectSelectedFilterTagsList);
  const searchSubjectValue = useSelector(selectSearchSubjectsValue);
  const filterTagsList = useSelector(selectFilterTagsList);

  const [filteredSubjects, setFilteredSubjects] = useState<IOfferedSubject[]>([]);
  const [filtro, setFiltro] = useState("OBLIGATORIO");
  const [subjectsInSearch, setSubjectsInSearch] = useState<IOfferedSubject[]>([]);
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [reserveError, setReserveError] = useState<IReserveError>({});
  const showError = useToastError();

  const token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  }

  /* -------------------------------------------------------------------------- */
  /*                             SET OFERTA if USER                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (offeredSubjects && offeredSubjects.length) {
      dispatch(setStudentInicializado(2));
      const result = offeredSubjects.filter((s) => s.tipoMateria === filtro);
      setFilteredSubjects(result);
    } else {
      dispatch(setStudentInicializado(0));
    }
  }, [offeredSubjects, filtro]);

  /* -------------------------------- Busqueda + Filtros---------------------- */
  useEffect(() => {
    if (filteredSubjects && searchSubjectValue.trim()?.length > 0) {
      const newSubjects = filterSubjects(filteredSubjects, searchSubjectValue);
      const newSubjectsWithFilter = filterSubjectsByTag(newSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjectsWithFilter);
    } else if (filteredSubjects) {
      const newSubjects = filterSubjectsByTag(filteredSubjects, selectedFilterTagsListStore || []);
      setSubjectsInSearch(newSubjects);
    }
  }, [searchSubjectValue, selectedFilterTagsListStore, filteredSubjects]);

  const handleScroll = () => {
    setScrollPosition(Number(scrollTargetRef.current?.scrollTop));
  };

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleReserve = async (selectedSection: ISelectedSection) => {
    let reserveResult = false;
    try {
      dispatch(setInTransitInscriptionRequest(true));
      if (selectedSection) {
        const results = await reserveSection({
          idC: selectedSection.idC,
          idS: selectedSection.idS,
          idA: selectedSection.idA,
          tim: selectedSection.tim,
          tic: selectedSection.tic,
          idPersona: student.id.toString(),
        });

        if (results.data.responseCode !== 200) {
          dispatch(removeSelectedOfferedSubject(selectedSection.idS));
          setReserveError({
            ...reserveError,
            [selectedSection.idC]: results.data.responseMessage,
          });
          showError(results.data.responseMessage, () =>
            setReserveError((prevState) => {
              const newState = prevState;
              delete newState[selectedSection.idC];
              return newState;
            })
          );
        } else {
          reserveResult = true;
          await dispatch(setLoadingRequestInTransit(true));
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
      reserveResult=false;
    } finally {
      dispatch(setInTransitInscriptionRequest(false));
    }
    return reserveResult;
  };

  const handleSelectTab = (tipo: string) => {
    if (tipo) {
      setFiltro(tipo);
    } else {
      setFiltro("OBLIGATORIO");
    }
  };

  const handleOpenFilter = () => {
    dispatch(setPanelFilters(true));
  };

  const scrollToTop = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollTop = 0;
    }
  };

  const renderSubjects = (subjects: IOfferedSubject[], listadoFiltros: string[], cajaBusqueda: string) => {
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    return (
      <>
        {subjects.length === 0 && (listadoFiltros?.length || cajaBusqueda) && (
          <p>No coinciden materias con la búsqueda o filtros.</p>
        )}
        {subjects.length === 0 && !(listadoFiltros?.length || cajaBusqueda) && <p>No hay materias disponibles.</p>}
        {subjects.map((s) => {
          const notPinnedOrIsncribedSections = s.sections.filter(
            (curso) => pinnedSubjectsList.indexOf(curso.id) === -1 && inscribedSubsIds.indexOf(curso.id) === -1
          );
          return (
            <DesktopSubjectList
              key={s.id}
              onReserve={(section: ISelectedSection) => handleReserve(section)}
              selectedSubjects={selectedSubjects}
              error={reserveError}
              subjectId={s.id}
              subjects={notPinnedOrIsncribedSections}
              quotas={subjectsQuotas ? subjectsQuotas : {}}
              title={s.title}
            />
          );
        })}
      </>
    );
  };
  const renderPinnedSubjects = (subjects: IOfferedSubject[]) => {
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    return (
      <>
        {subjects.map((s) => {
          const pinnedSections = s.sections.filter(
            (curso) => pinnedSubjectsList.indexOf(curso.id) > -1 && inscribedSubsIds.indexOf(curso.id) === -1
          );

          return (
            <DesktopSubjectList
              key={s.id}
              onReserve={(section: ISelectedSection) => handleReserve(section)}
              selectedSubjects={selectedSubjects}
              error={reserveError}
              subjectId={s.id}
              subjects={pinnedSections}
              quotas={subjectsQuotas ? subjectsQuotas : {}}
              title={s.title}
            />
          );
        })}
      </>
    );
  };
  
  const countPinnedByType = (subjects: IOfferedSubject[]) => {
    let count = 0;
    const inscribedSubsIds = inscribedSubjects?.map((s) => s.idCurso) || [];
    subjects.forEach((s) => {
      s.sections.forEach((curso) => {
        if (pinnedSubjectsList.indexOf(curso.id) > -1 && inscribedSubsIds.indexOf(curso.id) === -1) {
          count++;
        }
      });
    });
    return count;
  };

  return (
    <div className="desktop-offer-list-container">
      {filteredSubjects.length === 0 && student.id && offeredSubjects === null ? (
        <Loader text="Cargando" />
      ) : (
        <>
          <div className="desktop-filters-nav">
            <DesktopTab filtro={filtro} handleSelectTab={handleSelectTab} />
            <SearchInput />
            {filtro !== "OBLIGATORIO" && filterTagsList && filterTagsList?.length > 0 &&
            <button
              onClick={handleOpenFilter}
              className={`filters-btn ${selectedFilterTagsListStore?.length ? "filters-btn-active" : ""}`}
            >
              <SlIcon name="filter"></SlIcon>
            </button>
            }
          </div>
          <div onScroll={handleScroll} ref={scrollTargetRef} className="desktop-filters-listing">
            {pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0 && (
              <div className="desktop-title-2 pinned-title-2">
                <div className="desktop-title-2-first-col">
                  <h2 className="desktop-title-2-text">
                    Materias Fijadas {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}
                  </h2>
                  <span className="list-subjects-counter">{countPinnedByType(filteredSubjects)}</span>
                </div>

                {scrollPosition > 100 && <SlIcon onClick={scrollToTop} className="go-to-top" name="arrow-bar-up"></SlIcon>}
              </div>
            )}

            {pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0 && (
              <div className="desktop-pinned-subject-list">{renderPinnedSubjects(filteredSubjects)}</div>
            )}

            <div
              className={`desktop-title-2 ${
                pinnedSubjectsList.length > 0 && countPinnedByType(filteredSubjects) > 0
                  ? "offered-title-sub-2"
                  : "offered-title-2"
              }`}
            >
              <div className="desktop-title-2-first-col">
                <h2 className="desktop-title-2-text">
                  Materias {filtro === "OBLIGATORIO" ? "Obligatorias" : "Optativas"}
                </h2>
              </div>
              {scrollPosition > 100 && <SlIcon onClick={scrollToTop} className="go-to-top" name="arrow-bar-up"></SlIcon>}
            </div>

            <div className="desktop-offered-subject-list">
              {searchSubjectValue?.length > 0 || (selectedFilterTagsListStore && selectedFilterTagsListStore?.length > 0)
                ? renderSubjects(subjectsInSearch, selectedFilterTagsListStore || [], searchSubjectValue)
                : renderSubjects(filteredSubjects, selectedFilterTagsListStore || [], searchSubjectValue)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DesktopOfferList;
