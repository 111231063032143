import { useEffect, useRef } from "react";
import { conf } from "../../utils/config";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import { selectStudent, setToken } from "../../reduxSlices/studentSlice";
import {
  selectSubjectsQuotas,
  setDetailSubjectOffered,
  setLoadingRequestInTransit,
} from "../../reduxSlices/subjectsSlice";

import { SocketConfig, SocketState } from "../../SocketConfig";
import { isJsonString } from "../../utils";
import { websocketTypes } from "../../enums/websocket.enum";
import {
  removeSelectedOfferedSubject,
  setInscribedSubjects,
  setOfferedSubjects,
  setSubjectsQuota,
} from "../../reduxSlices/subjectsSlice";
import { getCuposCurso } from "../../Api/reservation";
import { IQuota } from "../../types";
import { showToastWarning, useToastError, useToastInfo } from "../../hooks/useToast";
import { getMateriasAlumno, getMateriasInscriptasAlumno } from "../../Api/subject";
import { useMsal } from "@azure/msal-react";
import { updateCupoCurso } from "../../reduxAsyncThunks/subjects";
import { useAppDispatch } from "../../app/store";
import { updatePrioridadAlumno } from "../../reduxAsyncThunks/prioridad";
import {
  setInTransitInscriptionRequest,
  setPanelFilters,
  setPanelSubjectInscribed,
  setPanelSubjectOffered,
} from "../../reduxSlices/globalFlagsSlice";

const previousHeartbeats: Record<string, number> = {};

const checkIfQuotaExist = (subjectsQuotas: Record<string, IQuota>, sectionId: number, quotaId: number) => {
  const sectionIds = Object.keys(subjectsQuotas);
  if (sectionIds.some((s) => Number(s) === sectionId)) {
    //VER SI ALGUNO ES quotaID
    //TODO
    return true;
  } else {
    return false;
  }
};

export default function WebsocketManager() {
  const showError = useToastError();
  const showInfo = useToastInfo();
  const student = useSelector(selectStudent);
  const dispatch = useAppDispatch();
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const { instance } = useMsal();
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;

  const handleLogout = async () => {
    localStorage.clear();
    await dispatch(setToken(""));
    await instance.logoutRedirect({
      postLogoutRedirectUri: redirectUri,
    });
  };

  const showWarningMessage = async (message: string) => {
    showToastWarning(message);
  };

  const handleCambioPrioridad = async () => {
    await dispatch(updatePrioridadAlumno());
  };

  const heartbeatIntervalRef = useRef<number>();
  const socketHeartBeatUrl = conf("WSHB");
  const socketUrl = conf("WS");
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${socketUrl}?idPersona=${student.id}`,
    SocketConfig,
    !!student?.id
  );

  const handleRegenerarOferteEInscripciones = async () => {
    dispatch(setInTransitInscriptionRequest(true));
    dispatch(setDetailSubjectOffered(null));
    dispatch(setPanelSubjectInscribed(false));
    dispatch(setPanelFilters(false));
    /* ---------------------------- Regenerar oferta ---------------------------- */
    const cursos = await getMateriasAlumno(String(student.id));
    if (cursos && cursos.status === 200) {
      console.log("ACTUALIZANDO OFERTA X WS...");
      dispatch(setOfferedSubjects(cursos.data));
    }
    /* ------------------------- regenerar inscripciones ------------------------ */
    const results = await getMateriasInscriptasAlumno(student.id);
    if (results.status === 200) {
      console.log("ACTUALIZANDO INSCRIPCIONES X WS...");
      dispatch(setInscribedSubjects(results.data));
    }
    dispatch(setInTransitInscriptionRequest(false));
  };

  /* -------------------------------------------------------------------------- */
  /*                                CONECTADO WS                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    console.log("%cWebSocket state :>> ", "color: yellow;", SocketState.get(readyState));
    if (readyState === ReadyState.OPEN) {
      sendMessage("Connection established");
    }
  }, [readyState]);

  /* -------------------------------------------------------------------------- */
  /*                             READING WS Messages                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (lastMessage && isJsonString(lastMessage.data)) {
      const socketMsg = JSON.parse(lastMessage.data);
      console.log("EVENTO WS EN APP --->", socketMsg);
      /* ---------------------------- IF socket NOT OK ---------------------------- */
      if (socketMsg.code !== 200 && socketMsg.dataJson) {
        const idPersona = socketMsg.dataJson.personaId;
        if (idPersona === student.id) {
          showError(socketMsg.message);
        }

        switch (socketMsg.type) {
          case websocketTypes.RESPUESTA_RESERVA:
          case websocketTypes.RESPUESTA_CONFIRMACION_INSCRIPCION:
            dispatch(removeSelectedOfferedSubject(socketMsg.dataJson.cursoId));
            dispatch(setLoadingRequestInTransit(false));
            break;
          default:
            break;
        }
      }
      /* ----------------------------- IF socket is OK ---------------------------- */
      if (socketMsg.code === 200 && socketMsg.type) {
        const idPersona = socketMsg.dataJson.personaId;

        if (idPersona === student.id && socketMsg?.message?.length > 0) {
          showInfo(socketMsg.message);
        }

        switch (socketMsg.type) {
          case websocketTypes.LOGOUT_GENERAL:
            handleLogout();
            break;
          case websocketTypes.WARNING_MENSAJE:
            showWarningMessage(socketMsg.message);
            break;
          case websocketTypes.CAMBIO_PRIORIDAD:
            handleCambioPrioridad();
            break;
          default:
            break;
        }

        if (
          (socketMsg.type && socketMsg.type === websocketTypes.ACTUALIZACION_OFERTA_KV) ||
          socketMsg.type === websocketTypes.ACTUALIZACION_INSCRIPCION_KV
        ) {
          handleRegenerarOferteEInscripciones();
        }

        const handleGetCuposCurso = async (message: string) => {
          const { dataJson } = JSON.parse(message);
          const sectionId = dataJson.cursoId;

          if (dataJson && dataJson.cupoId) {
            const quotaId = dataJson.cupoId;
            const cuotaExist = subjectsQuotas && checkIfQuotaExist(subjectsQuotas, sectionId, quotaId);
            if (cuotaExist) {
              await dispatch(updateCupoCurso(quotaId));
            }
          }
        };
        handleGetCuposCurso(lastMessage.data);
      }
    }
  }, [lastMessage]);

  /* -------------------------------------------------------------------------- */
  /*                                 HEART BEAT                                 */
  /* -------------------------------------------------------------------------- */
  // Sends a periodical heartbeat message through the websocket connection.
  useEffect(() => {
    // TODO: revisar el heartbeat y multiples renders
    if (readyState === 1) {
      heartbeatIntervalRef.current = window.setInterval(() => {
        if (socketHeartBeatUrl) {
          const lastHeartbeat = previousHeartbeats[socketHeartBeatUrl];
          const deltaFromNow = (Date.now() - lastHeartbeat) / 1000;
          // Send a heartbeat message if it hasn't already been sent within the last 10 seconds.
          if (!lastHeartbeat || deltaFromNow > 5) {
            // Send the heartbeat message and update the heartbeat history.
            // console.log("Sending heart beat...");
            sendMessage("ping");
            previousHeartbeats[socketHeartBeatUrl] = Date.now();
          }
        }
      }, 10000);
    }

    return () => {
      clearInterval(heartbeatIntervalRef.current);
    };
  }, [socketHeartBeatUrl, readyState, sendMessage]);

  return <></>;
}
