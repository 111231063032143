import { useDispatch } from "react-redux";
import { desinscribirDeCurso } from "../Api/inscription";
import {
  removeInscribedSubject,
  removeSelectedOfferedSubject,
  setLoadingRequestInTransit,
} from "../reduxSlices/subjectsSlice";
import { useToastError, useToastInfo } from "./useToast";

const useUnsubscribeSection = () => {
  const dispatch = useDispatch();
  const showError = useToastError();
  const showInfo = useToastInfo();
  
  const handleBajaCurso = async (sectionId: number): Promise<boolean> => {
    try {
      const response = await desinscribirDeCurso(sectionId);
      if (response.status === 200) {
        showInfo("Procesando desinscripción...")
        if (response.data.resultado >= 0) {
          await dispatch(removeSelectedOfferedSubject(sectionId));
          await dispatch(removeInscribedSubject(sectionId)); // TEMP REMOVE INSCRIBED SUBJECT
        } else {
          showError(response.data.mensaje);
        }
        await dispatch(setLoadingRequestInTransit(true));
        return true;
      } else {
        throw new Error("Request failed");
      }
    } catch (error) {
      return false;
    }
  };

  return handleBajaCurso;
};

export default useUnsubscribeSection;
