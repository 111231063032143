import "./ModalOverlay.css";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";

interface IPropsModalOverlay {
  text: string;
}

const ModalOverlay = ({  text }: IPropsModalOverlay) => {
  return (
    <div className="full-screen-modal-overlay">
      <SlSpinner
        style={{
          fontSize: "3rem",
          //@ts-ignore
          "--indicator-color": "#00C4B3",
          "--track-color": "#ccebe8",
        }}
      />
      <div className="full-screen-modal-overlay-text">{text}</div>
    </div>
  );
};

export default ModalOverlay;
