import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { conf } from "../../utils/config";
import { useSelector } from "react-redux";
import { selectToken, setToken } from "../../reduxSlices/studentSlice";
import { useAppDispatch } from "../../app/store";
import { selectParameter } from "../../reduxSlices/parametersSlice";
import { AppState, AppStateType } from "../../enums/appState.enum";

export enum ServerStatus {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  InternalServerError = 500,
}

function AxiosManager() {
  const token = useSelector(selectToken);
  const dispatch = useAppDispatch();
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const defaultConfig: AxiosRequestConfig = {
    baseURL: conf("BASE_URL"),
  };
  const { instance } = useMsal();
  const { started, appState } = useSelector(selectParameter);

  const handleLogout = async (state: string) => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(setToken(""));
    if (state !== AppState.BY_PASS) {
      await instance.logoutRedirect({
        postLogoutRedirectUri: redirectUri,
      });
    }
  };

  useEffect(() => {
    // interceptor use after app starts
    if (started) {
      axios.interceptors.response.use(
        (response) => {
          console.log("status:", response.status);
          console.log("MANAGER---", appState);
          if (response.status === ServerStatus.Unauthorized) {
            handleLogout(appState);
          }
          return response;
        },
        (error: AxiosError) => {
          if (error.response?.status === ServerStatus.Unauthorized) {
            handleLogout(appState);
          }
          return Promise.reject(error);
        }
      );
    }
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = defaultConfig.baseURL;
        const tkn = localStorage.getItem("token");
        if (tkn === "x") {
          handleLogout(appState);
        }
        const newToken = token.length > 0 ? token : tkn;
        if (newToken) {
          config.headers = {
            ...config.headers,
            Authorization: "Bearer " + newToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          } as AxiosRequestHeaders;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token, appState, started]);

  return <></>;
}

export default AxiosManager;
