import React, { Fragment, useEffect, useState } from "react";
import "./DesktopSubjectItem.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { LiaSpinnerSolid } from "react-icons/lia";
import {
  CalendarValidationResponse,
  ICalendarSubjects,
  IDesktopCalendarSubjects,
  IOfferedReserveData,
  IOfferedSubjectByType,
  IOfferedSubjectData,
  IQuota,
  ISelectedSection,
  ISubjectData,
  ISubjectsByType,
} from "../../types";
import { getQuotaColor, parseHours, transformHorariosData } from "../../utils";
import { selectEditionMode } from "../../reduxSlices/editorModeSlice";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa6";
import { BsFillPinAngleFill } from "react-icons/bs";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { checkCalendarizeSubject, getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import {
  addCalendarSubject,
  addCalendarSubjectDemoCount,
  removeCalendarSubject,
  selectCalendarSubjectsDemoCount,
  selectCalendarSubjectsList,
  setDetailSubjectOffered,
  setPinnedSubjectsList,
  subtractCalendarSubjectDemoCount,
} from "../../reduxSlices/subjectsSlice";
import { useAppDispatch } from "../../app/store";
import { selectInTransitInRquest, setPanelFilters } from "../../reduxSlices/globalFlagsSlice";
import { useToastError } from "../../hooks/useToast";
import Divider from "../Divider/Divider";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";

// import 'animate.css';

interface IPropsSubjectItem {
  id: number;
  subject: string;
  subjectId: string;
  section: string;
  selected: boolean;
  subjectData: IOfferedSubjectData;
  quota: IQuota;
  onChange: (data: ISelectedSection) => Promise<boolean>;
  disabled: boolean;
  pinned: boolean;
  creditos: number;
  sectionType: string;
}

const DesktopSubjectItem = ({
  subject,
  section,
  id,
  selected,
  subjectData,
  quota,
  onChange,
  subjectId,
  disabled,
  pinned,
  creditos,
  sectionType,
}: IPropsSubjectItem) => {
  const student = useSelector(selectStudent);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const calendarSubsDemoCount = useSelector(selectCalendarSubjectsDemoCount);
  const inTransitInscriptionRequet = useSelector(selectInTransitInRquest);
  const dispatch = useAppDispatch();
  const [outAnimation, setOutAnimation] = useState("");
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [sendInscription, setSendInscription] = useState(false);
  const [hasClick, setHasClick] = useState(false);

  const showError = useToastError();

  const subType = sectionType === "OPTATIVO" ? "Optativa" : "Obligatoria";

  useEffect(() => {
    if (inTransitInscriptionRequet && hasClick) {
      setSendInscription(true);
      setTimeout(() => {
        setHasClick(false);
        setSendInscription(false);
      }, 10000);
    }
  }, [inTransitInscriptionRequet]);

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handlePinSubject = async (idCurso: number, pinned: boolean) => {
    if (pinned) {
      setOutAnimation("animate__slideOutRight");
    }
    const result = await toggleMateriaFijadaAlumno(student.id, idCurso);
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    } else {
      alert("Error al fijar materia");
    }
  };
  /* ----------------------------- ADD to Calendar ---------------------------- */
  const handleCheckIfCalenderize = async (reserveData: IOfferedReserveData) => {
    let result;
    //Si ya fue calendarizado, quitarlo
    if (calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === id)) {
      return true;
    }
    setCalendarLoading(true);
    const { data, status } = await checkCalendarizeSubject(reserveData);
    const { responseCode, responseMessage } = data;
    if (responseCode === -1) {
      showError(responseMessage);
      result = false;
    } else {
      result = true;
    }
    setCalendarLoading(false);
    return result;
  };

  const handleToggleCalendarSubject = async (idCurso: number, reserveData: IOfferedReserveData) => {
    // const validation = await handleCheckIfCalenderize(reserveData);
    // if (!validation) {
    //   return;
    // }

    const exist = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === idCurso);
    if (exist) {
      dispatch(removeCalendarSubject(idCurso));
      dispatch(subtractCalendarSubjectDemoCount());

      return;
    }

    let listado: IDesktopCalendarSubjects[] = [];

    dispatch(addCalendarSubjectDemoCount());

    subjectData.subjectsByType.forEach((dia) => {
      dia.subjectInfo.forEach((horario: any) => {
        const newDemo: IDesktopCalendarSubjects = {
          curso: section,
          desde: horario.values.desde,
          hasta: horario.values.hasta,
          dia: horario.values.dia,
          idCurso: idCurso,
          idMateria: Number(subjectId),
          profesor: horario.values.profesores[0],
          warning: false,
          realInscribed: false,
          superpuesta: 0,
        };

        listado.push(newDemo);
      });
    });
    listado.forEach((horario) => dispatch(addCalendarSubject(horario)));
  };

  const handleModalPanelInfo = (subjectId: number, cursoId: number) => {
    dispatch(setDetailSubjectOffered({ subjectId, cursoId }));
    dispatch(setPanelFilters(false));
  };

  const handlTryReserve = async () => {
    setHasClick(true);
    const result = await onChange({
      tic: subjectData.reserveData.tic,
      tim: subjectData.reserveData.tim,
      idC: subjectData.reserveData.idC,
      idA: subjectData.reserveData.idA,
      idS: subjectData.reserveData.idS,
      section,
      subjectId: subjectId,
    });
  };

  return (
    // <div className={`desktop-subject-item ${selected ? "subject-item-selected" : ""} ${pinned ? "desktop-pinned-subject" : ""} ${pinned ? "animate__slideInDown" : "animate__slideInRight"} animate__animated animate__faster ${outAnimation}`}>
    <div
      className={`desktop-subject-item ${pinned ? "desktop-pinned-subject" : ""} ${
        quota && quota.availability === 0 ? "bg-gray-noquota" : ""
      }`}
    >
      <div className="desktop-item-first-row">
        {quota && quota?.qty >= 0 && student.prioridad && (
          <>
            <FaUser
              style={{ color: quota ? getQuotaColor(quota.availability) : "black" }}
              className="desktop-icon-user-quota"
            />
          </>
        )}
        <p
          className={`desktop-item-title ${quota && quota.availability === 0 ? "desktop-item-title-noquota" : ""}`}
          onClick={() => handleModalPanelInfo(Number(subjectId), Number(id))}
        >
          {section}
        </p>
        <div className="desktop-items-actions">
          {pinned && <p className="subject-item-type">{subType}</p>}
          <button className="desktop-action btn-pin" title="Fijar materia" onClick={() => handlePinSubject(id, pinned)}>
            <BsFillPinAngleFill />
          </button>
          {student.mostrarHorarios && (
            <button
              className={`desktop-action ${
                calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === id) ? "btn-calendar" : ""
              } ${calendarLoading ? "calendar-loading-icon" : ""}`}
              title="Visualizar en calendario"
              // onClick={() => handleToggleCalendarSubject(id)}
              onClick={() => handleToggleCalendarSubject(Number(id), subjectData.reserveData)}
              disabled={calendarLoading}
            >
              <BsFillCalendarWeekFill />
            </button>
          )}
          {quota && quota.availability === 0 && (
            <button className="desktop-action-add-noquota" disabled>
              <SlIcon name="x"></SlIcon>
              Sin cupo
            </button>
          )}
          {student.prioridad && quota && quota.availability > 0 && (
            <button
              className={`desktop-action-add ${sendInscription ? "desktop-action-add-disabled" : ""}`}
              onClick={handlTryReserve}
              disabled={inTransitInscriptionRequet}
            >
              {sendInscription ? (
                <>
                  <LiaSpinnerSolid className="spinner-animation" /> Procesando
                </>
              ) : (
                <>
                  <SlIcon name="plus"></SlIcon>
                  Inscribirme
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <Divider />
      <div className="desktop-item-second-row">
        <ProfessorAndSubjectHours subjectData={subjectData} allProfesVisible={false}/>
        {creditos > 0 ? (
          <div className="desktop-inner-col">
            <p className="desktop-subject-type">Créditos</p>
            <p>{creditos}</p>
          </div>
        ) : (
          <div className="desktop-inner-col"></div>
        )}
      </div>
    </div>
  );
};

export default DesktopSubjectItem;
